var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"min-height":"100vh"}},[_c('div',{staticClass:"px2 border-box md-py4 mobile-px2 py3"},[_c('div',{staticClass:"center h0 helvetica-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{class:`center h0 open-sans-bold ${
        _vm.modelsList.length > 1 ? 'mb4' : 'mb1'
      }`},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.modelsList.length > 1),expression:"modelsList.length > 1"}],staticClass:"rsf-select-wrapper mx-auto relative col-12 max-width-3 mb3 mobile-mx-auto"},[_c('svg',{attrs:{"width":"12","height":"8","viewBox":"0 0 12 8","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M10.7695 1.5L5.88481 6.5L1.00008 1.5","stroke":"black","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedModel),expression:"selectedModel"}],staticClass:"rsf-select h5 color-black open-sans-regular pointer reviews-product-select",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectedModel=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){_vm.fetchReviews(_vm.selectedModel.RNumber);
          _vm.showImage = false;}]}},_vm._l((_vm.modelsList),function(model){return _c('option',{key:model.RNumber,domProps:{"value":model}},[_vm._v(" "+_vm._s(model.Model)+" ")])}),0)]),_c('div',{staticClass:"flex flex-column items-center"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showImage),expression:"!showImage"}],staticClass:"flex items-center",staticStyle:{"height":"450px"}},[_c('img',{staticClass:"block center mx-auto my3",attrs:{"src":"https://static.redseafish.com/wp-content/uploads/2023/09/07130304/spinner2.gif"}})]),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.showImage),expression:"showImage"}],staticClass:"fit contain",staticStyle:{"aspect-ratio":"1/1","height":"450px"},attrs:{"src":_vm.selectedModel.mainImage},on:{"load":function($event){_vm.showImage = true}}})]),_c('div',{staticClass:"md-grid-3-col grid-1-col max-width-5 mx-auto"},[_c('div'),_c('rating',{attrs:{"reviewCount":_vm.reviewCount,"rating":Math.round(_vm.avgRating * 10) / 10,"reviews":_vm.reviews,"loadingReviews":_vm.isLoading}}),_c('div',{staticClass:"flex flex-column justify-center md-items-end items-center"},[(_vm.showWriteReviewBtn == 'true')?_c('div',{staticClass:"rsf-btn rsf-btn-secondary color-1 bg-color-white mobile-mb1 h-fit-content",on:{"click":function($event){return _vm.$refs['add-review'].open()}}},[_vm._v(" "+_vm._s(_vm.$t("reviews_write_review"))+" ")]):_vm._e()])],1)]),_c('div',{staticClass:"px2 py3 bg-color-11 mobile-px2 mobile-py3"},[(_vm.reviews.length > 0 && !_vm.isLoading)?_c('div',{staticClass:"flex-column gap3 mb3 bg-color-11"},_vm._l((_vm.reviews),function(review,index){return (
          _vm.moreReviewsLoaded
            ? index >= 0 && index <= _vm.displayCount - 1
            : index < _vm.displayCount
        )?_c('review-item',{key:review.reviewId,attrs:{"review":review,"openMediaModal":_vm.openMediaModal,"modelName":_vm.selectedModel.Model}}):_vm._e()}),1):_vm._e(),(_vm.reviews.length == 0 && !_vm.isLoading && _vm.selectedModel.RNumber != '')?_c('div',{staticClass:"flex-column gap3 mb3 pt3"},[_c('h2',{staticClass:"center h2 open-sans-bold mb3"},[_vm._v(" "+_vm._s(_vm.$t("reviews_no_reviews"))+" ")]),(_vm.showWriteReviewBtn == 'true')?_c('div',{staticClass:"rsf-btn rsf-btn-secondary color-1 bg-color-tranpsarent mx-auto mb3 mobile-mb1",on:{"click":function($event){return _vm.$refs['add-review'].open()}}},[_vm._v(" "+_vm._s(_vm.$t("reviews_write_review"))+" ")]):_vm._e()]):_vm._e(),(_vm.isLoading)?_c('div',{staticClass:"flex-column gap3 mb3"},[_c('img',{staticClass:"block center mx-auto my3",attrs:{"src":"https://static.redseafish.com/wp-content/uploads/2023/09/07130304/spinner2.gif"}})]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.reviews.length > 5 && _vm.displayCount <= _vm.reviews.length),expression:"reviews.length > 5 && displayCount <= reviews.length"}],staticClass:"rsf-btn rsf-btn-tertiary color-1 mx-auto",staticStyle:{"text-decoration-thickness":"1px"},on:{"click":function($event){_vm.moreReviewsLoaded = true;
        _vm.displayCount = _vm.displayCount + _vm.showReviewsIncrement;}}},[_c('span',{staticClass:"open-sans-semibold h5"},[_vm._v(_vm._s(_vm.$t("reviews_load_more")))]),_c('svg',{attrs:{"width":"12","height":"8","viewBox":"0 0 12 8","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M11 1.4502L6 6.4502L1 1.45019","stroke":"#1867FF","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])])]),_c('rsf-modal',{ref:"add-review"},[_c('write-review',{attrs:{"modelsList":_vm.modelsList,"getModalRef":() => _vm.$refs['add-review']}})],1),_c('carousel-modal',{ref:"media-modal"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }